import { Box, Dialog, Hidden, styled, useMediaQuery, useTheme } from '@mui/material'
import { useImgix } from 'core/services/imgix/useImgix'
import React, { useRef, useState } from 'react'
import { Keyboard, Lazy, Navigation, Zoom } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Loader from '../common/loader'
import CloseButton from './components/closeButton'

interface Props {
    open: boolean
    images: string[]
    current: number
    onClose: () => void
}

const NavigationButton = styled(Box)(({ theme, color }) => ({
    'color':
        (color === 'primary' ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText) +
        ' !important',
    '&:after': {
        borderColor:
            (color === 'primary'
                ? theme.palette.primary.contrastText
                : theme.palette.secondary.contrastText) + ' !important',
        textShadow:
            '0px 0px 2px ' +
            (color === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main),
    },
})) as typeof Box

const ZoomDialog: React.FC<Props> = ({ open, images, current, onClose }) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true })
    const { getImage } = useImgix()
    const getImageSource = (picture?: string | null, dpr?: number) => {
        const image = picture ?? process.env.GATSBY_ASSETS_URL + '/media/establishment/default.jpg'
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        let height =
            (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) * 0.9
        if (fullScreen) {
            height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
        }
        const targetWidth = Math.round(width)
        const targetHeight = Math.round(height)
        return {
            height: targetHeight,
            width: targetWidth,
            image: getImage(image, { w: targetWidth, h: targetHeight, fit: 'clip', dpr }),
        }
    }
    const [spinner, setSpinner] = useState(true)
    const navigationPrevRef = useRef<HTMLDivElement>(null)
    const navigationNextRef = useRef<HTMLDivElement>(null)
    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="xl"
                fullScreen
                sx={{
                    '& .MuiDialog-paper': {
                        bgcolor: 'transparent',
                    },
                }}
            >
                {open && spinner && (
                    <div style={{ height: '40vh', width: '40vh' }}>
                        <Loader bgcolor="transparent" />
                    </div>
                )}
                <Box
                    sx={{
                        bgcolor: 'primary.main',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CloseButton onClose={onClose} />
                    <Swiper
                        modules={[Navigation, Zoom, Lazy, Keyboard]}
                        slidesPerView={1}
                        zoom
                        lazy
                        keyboard
                        centerInsufficientSlides
                        initialSlide={current}
                        style={{
                            height: '100%',
                            width: '100%',
                        }}
                        onInit={(swiper) => {
                            const navigationPrevElement = navigationPrevRef.current
                            const navigationNextElement = navigationNextRef.current
                            const navigation = swiper.params.navigation
                            if (navigationPrevElement && navigationNextElement && navigation) {
                                swiper.navigation.destroy()
                                // @ts-ignore
                                navigation.prevEl = navigationPrevElement
                                // @ts-ignore
                                navigation.nextEl = navigationNextElement
                                swiper.navigation.init()
                                swiper.navigation.update()
                            }
                        }}
                    >
                        <Hidden implementation="css">
                            <NavigationButton
                                color={'primary'}
                                component={'div'}
                                ref={navigationPrevRef}
                                className="swiper-button-prev"
                            />
                            <NavigationButton
                                color={'primary'}
                                component={'div'}
                                ref={navigationNextRef}
                                className="swiper-button-next"
                            />
                        </Hidden>
                        {images.map((image, index) => {
                            const { image: baseImage } = getImageSource(image)
                            return (
                                <SwiperSlide
                                    key={'zoom' + index}
                                    zoom
                                    style={{
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img
                                        onLoad={() => setSpinner(false)}
                                        src={baseImage}
                                        srcSet={
                                            getImageSource(image, 1).image +
                                            ' 1x, ' +
                                            getImageSource(image, 2).image +
                                            ' 2x, ' +
                                            getImageSource(image, 3).image +
                                            ' 3x'
                                        }
                                        height="100%"
                                        width="100%"
                                    />
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </Box>
            </Dialog>
        </>
    )
}

export default ZoomDialog
