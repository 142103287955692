import { Facebook, Google, Instagram, Twitter } from '@mui/icons-material'
import {
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    IconButton,
    Link,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { EstablishmentModel } from 'core/models/establishmentModel'
import { MenuBoardImage } from 'core/models/menuBoardModel'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useRef } from 'react'
import { Anchorme } from 'react-anchorme'
import Section from './common/section'
import { Title } from './common/title'
import { UserText } from './common/userText'
import Slider from './slate/slider'

interface Props {
    establishment: EstablishmentModel
    color: 'primary' | 'secondary'
    onZoom: (images: string[], current: number) => void
}

const SlateSection = React.forwardRef<HTMLDivElement, Props>(
    ({ establishment: { menuBoardData, websiteSections }, color, onZoom }, ref) => {
        const { t } = useTranslation()

        const id = 'slate'
        const title = t('menu.slate')
        const section = websiteSections?.find((section) => section.section === id)

        const theme = useTheme()
        const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true })
        const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true })
        const spacing = isMobileScreen ? 2 : 4

        const navigationPrevRef = useRef<HTMLDivElement>(null)
        const navigationNextRef = useRef<HTMLDivElement>(null)
        let images: Array<MenuBoardImage> = []
        if (menuBoardData && menuBoardData.picture && (menuBoardData.images?.length ?? 0) > 0) {
            images.push(...(menuBoardData.images ?? []))
        } else if (menuBoardData?.picture) {
            images.push({
                picture: menuBoardData?.picture,
                comment: null,
            })
        }
        return (
            <Section
                ref={ref}
                id={id}
                title={title}
                color={color}
                backgroundImage={section?.backgroundImage ?? undefined}
                backgroundColor={section?.backgroundColor ?? undefined}
                overlayOpacity={section?.overlayOpacity ?? undefined}
            >
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        zIndex: 3,
                    }}
                    spacing={spacing}
                >
                    <Grid item xs={12}>
                        <Title
                            component="h2"
                            color={color}
                            sx={{
                                color: section?.textColor,
                            }}
                        >
                            {title}
                        </Title>
                    </Grid>
                    <Grid item xs={12}>
                        <Container maxWidth="xl">
                            <Slider
                                images={images}
                                isMobileScreen={isMobileScreen}
                                isLargeScreen={isLargeScreen}
                                navigationPrevRef={navigationPrevRef}
                                navigationNextRef={navigationNextRef}
                                color={color}
                                onZoom={(current) =>
                                    onZoom(
                                        images.map((image) => image.picture),
                                        current,
                                    )
                                }
                                spacing={spacing}
                            />
                        </Container>
                    </Grid>
                    <Grid item xs={12}>
                        <Container maxWidth="md">
                            <Card>
                                <CardContent>
                                    <UserText>
                                        <Anchorme
                                            linkComponent={(props) => (
                                                // @ts-ignore
                                                <Link {...props} />
                                            )}
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            {menuBoardData?.comment ?? ''}
                                        </Anchorme>
                                    </UserText>
                                    <CardActions sx={{ justifyContent: 'center' }}>
                                        {menuBoardData?.facebookPermalink && (
                                            <IconButton
                                                href={menuBoardData.facebookPermalink}
                                                target="_blank"
                                                rel="noopener"
                                            >
                                                <Facebook />
                                            </IconButton>
                                        )}
                                        {menuBoardData?.instagramPermalink && (
                                            <IconButton
                                                href={menuBoardData.instagramPermalink}
                                                target="_blank"
                                                rel="noopener"
                                            >
                                                <Instagram />
                                            </IconButton>
                                        )}
                                        {menuBoardData?.googleLocalPostPermalink && (
                                            <IconButton
                                                href={menuBoardData.googleLocalPostPermalink}
                                                target="_blank"
                                                rel="noopener"
                                            >
                                                <Google />
                                            </IconButton>
                                        )}
                                        {menuBoardData?.twitterPermalink && (
                                            <IconButton
                                                href={menuBoardData.twitterPermalink}
                                                target="_blank"
                                                rel="noopener"
                                            >
                                                <Twitter />
                                            </IconButton>
                                        )}
                                    </CardActions>
                                </CardContent>
                            </Card>
                        </Container>
                    </Grid>
                </Grid>
            </Section>
        )
    },
)

export default SlateSection
