import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'
import { Box, Container, Grid, Hidden, IconButton, styled, useMediaQuery, useTheme } from '@mui/material'
import clsx from 'clsx'
import { EstablishmentModel } from 'core/models/establishmentModel'
import { useImgix } from 'core/services/imgix/useImgix'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useRef } from 'react'
import { Keyboard, Lazy, Navigation, Zoom } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Section from './common/section'
import { Title } from './common/title'

interface Props {
    establishment: EstablishmentModel
    color: 'primary' | 'secondary'
    onZoom: (images: string[], current: number) => void
}

const NavigationButton = styled(Box)(({ theme, color }) => ({
    'color':
        (color === 'primary' ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText) +
        ' !important',
    '&:after': {
        borderColor:
            (color === 'primary'
                ? theme.palette.primary.contrastText
                : theme.palette.secondary.contrastText) + ' !important',
        textShadow:
            '0px 0px 2px ' +
            (color === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main),
    },
})) as typeof Box

const MenuSection = React.forwardRef<HTMLDivElement, Props>(
    ({ establishment: { menu, websiteSections }, color, onZoom }, ref) => {
        const { t } = useTranslation()

        const id = 'menu'
        const title = t('menu.menu')
        const section = websiteSections?.find((section) => section.section === id)

        const theme = useTheme()
        const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true })
        const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true })
        const { getImage } = useImgix()
        const columns = isMobileScreen ? 1 : 3
        const spacing = isMobileScreen ? 2 : 4
        const getImageSource = (picture?: string | null, dpr?: number) => {
            const image = picture ?? process.env.GATSBY_ASSETS_URL + '/media/establishment/default.jpg'
            let width = 1440
            if (isLargeScreen) {
                width = 1536
            } else if (isMobileScreen) {
                width = 300
            } else {
                if (typeof window !== 'undefined' && document) {
                    width =
                        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
                }
            }
            const targetWidth = Math.round((width - spacing * 8 * columns) / columns)
            const targetHeight = Math.round((targetWidth * 3) / 3)
            return {
                height: targetHeight,
                width: targetWidth,
                image: getImage(image, { w: targetWidth, h: targetHeight, fit: 'clip', dpr }),
            }
        }

        const navigationPrevRef = useRef<HTMLDivElement>(null)
        const navigationNextRef = useRef<HTMLDivElement>(null)
        const { height } = getImageSource(undefined)
        return (
            <Section
                ref={ref}
                id={id}
                title={title}
                color={color}
                backgroundImage={section?.backgroundImage ?? undefined}
                backgroundColor={section?.backgroundColor ?? undefined}
            >
                <Container maxWidth="xl">
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            zIndex: 3,
                        }}
                        spacing={spacing}
                    >
                        <Grid item xs={12}>
                            <Title
                                component="h2"
                                color={color}
                                sx={{
                                    color: section?.textColor,
                                }}
                            >
                                {title}
                            </Title>
                        </Grid>
                        <Grid item xs={12}>
                            <Swiper
                                height={height}
                                onInit={(swiper) => {
                                    const navigationPrevElement = navigationPrevRef.current
                                    const navigationNextElement = navigationNextRef.current
                                    const navigation = swiper.params.navigation
                                    if (navigationPrevElement && navigationNextElement && navigation) {
                                        swiper.navigation.destroy()
                                        // @ts-ignore
                                        navigation.prevEl = navigationPrevElement
                                        // @ts-ignore
                                        navigation.nextEl = navigationNextElement
                                        swiper.navigation.init()
                                        swiper.navigation.update()
                                    }
                                }}
                                modules={[Navigation, Zoom, Lazy, Keyboard]}
                                centerInsufficientSlides
                                slidesPerView={columns}
                                lazy
                                zoom={isMobileScreen}
                                keyboard
                                pagination={{
                                    clickable: true,
                                    renderBullet: (_, className) => {
                                        return (
                                            '<span class="' +
                                            className +
                                            '" style="background-color: ' +
                                            (color === 'primary' ? '#fff' : '#000') +
                                            '"></span>'
                                        )
                                    },
                                }}
                                spaceBetween={24}
                            >
                                <Hidden implementation="css">
                                    <NavigationButton
                                        color={color}
                                        component={'div'}
                                        ref={navigationPrevRef}
                                        className="swiper-button-prev"
                                    />
                                    <NavigationButton
                                        color={color}
                                        component={'div'}
                                        ref={navigationNextRef}
                                        className="swiper-button-next"
                                    />
                                </Hidden>
                                {menu.map(({ url }, index) => {
                                    const { image, height, width } = getImageSource(url)
                                    return (
                                        <SwiperSlide
                                            zoom={isMobileScreen}
                                            key={'menu' + index}
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: height,
                                                width: isMobileScreen ? width : 'inherit',
                                                overflow: 'hidden',
                                            }}
                                            itemProp="hasMenu"
                                            itemScope
                                            itemType="https://schema.org/Menu"
                                        >
                                            <>
                                                <div style={{ position: 'relative' }}>
                                                    <img
                                                        key={'menuImage' + index}
                                                        itemProp="image"
                                                        data-src={image}
                                                        data-srcset={
                                                            getImageSource(image, 1).image +
                                                            ' 1x, ' +
                                                            getImageSource(image, 2).image +
                                                            ' 2x, ' +
                                                            getImageSource(image, 3).image +
                                                            ' 3x'
                                                        }
                                                        {...{ content: getImage(url) }}
                                                        className="swiper-lazy"
                                                        height="100%"
                                                        width="100%"
                                                        alt={t('menu.menu')}
                                                    />
                                                    <IconButton
                                                        key={'menuImageZoom' + index}
                                                        onClick={() =>
                                                            onZoom(
                                                                menu.map((value) => value.url),
                                                                index,
                                                            )
                                                        }
                                                        sx={{
                                                            position: 'absolute',
                                                            bottom: 8,
                                                            right: 8,
                                                            bgcolor: 'primary.main',
                                                            borderColor: 'secondary.main',
                                                            borderWidth: 1,
                                                            borderStyle: 'solid',
                                                        }}
                                                        color="primary"
                                                        size={isMobileScreen ? 'medium' : 'large'}
                                                    >
                                                        <ZoomOutMapIcon
                                                            color="secondary"
                                                            fontSize="inherit"
                                                        />
                                                    </IconButton>
                                                </div>
                                                <div
                                                    className={clsx('swiper-lazy-preloader', {
                                                        'swiper-lazy-preloader-white': color === 'primary',
                                                        'swiper-lazy-preloader-black': color === 'secondary',
                                                    })}
                                                />
                                            </>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </Grid>
                    </Grid>
                </Container>
            </Section>
        )
    },
)

export default MenuSection
