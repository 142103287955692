import { Box, Button, styled, SvgIconProps } from '@mui/material'
import scrollTo from 'gatsby-plugin-smoothscroll'
import React from 'react'

interface Props {
    href: string
    Icon: React.ComponentType<SvgIconProps>
    text: React.ReactNode
    title?: string
    itemProp?: string
    content?: string
    color: 'primary' | 'secondary'
    openNewTab?: boolean
}

const ActionButton = styled(Button)(({ theme }) => ({
    'width': 350,
    'fontSize': '1.3rem',
    '.MuiButton-startIcon>*:nth-of-type(1)': {
        fontSize: '2rem',
    },
    [theme.breakpoints.only('xs')]: {
        width: 280,
        fontSize: '1rem',
    },
})) as typeof Button

const Action: React.FC<Props> = ({
    href,
    title,
    Icon,
    text,
    color,
    itemProp,
    content,
    openNewTab = true,
}) => {
    let additionnalProps = {}
    if (openNewTab) {
        additionnalProps = {
            target: '_blank',
            rel: 'noopener',
        }
    }
    return (
        <ActionButton
            color={color === 'primary' ? 'secondary' : 'primary'}
            variant="contained"
            startIcon={<Icon color={color} />}
            href={href}
            {...additionnalProps}
            title={title ?? href}
            itemProp={itemProp}
            onClick={(e) => {
                if (typeof window !== 'undefined' && href.startsWith('#')) {
                    e.preventDefault()
                    window.history.pushState({}, '', href)
                    scrollTo(href)
                }
            }}
            {...{ content: content }}
        >
            <Box component={'span'} sx={{ flexGrow: 1, textAlign: 'center' }}>
                {text}
            </Box>
        </ActionButton>
    )
}

export default Action
