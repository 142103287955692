import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'
import { Box, Hidden, IconButton, styled } from '@mui/material'
import clsx from 'clsx'
import { useImgix } from 'core/services/imgix/useImgix'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import { Keyboard, Lazy, Navigation, Zoom } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

interface Props {
    images: Array<any>
    isMobileScreen: boolean
    isLargeScreen: boolean
    navigationPrevRef: React.RefObject<HTMLDivElement>
    navigationNextRef: React.RefObject<HTMLDivElement>
    color: 'primary' | 'secondary'
    onZoom: (current: number) => void
    spacing: number
}

const NavigationButton = styled(Box)(({ theme, color }) => ({
    'color':
        (color === 'primary' ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText) +
        ' !important',
    '&:after': {
        borderColor:
            (color === 'primary'
                ? theme.palette.primary.contrastText
                : theme.palette.secondary.contrastText) + ' !important',
        textShadow:
            '0px 0px 2px ' +
            (color === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main),
    },
})) as typeof Box

const Slider: React.FC<Props> = ({
    images,
    navigationPrevRef,
    navigationNextRef,
    isMobileScreen,
    isLargeScreen,
    color,
    onZoom,
    spacing,
}) => {
    const { t } = useTranslation()
    const columns = isMobileScreen ? 1 : 3
    const { getImage } = useImgix()
    const getImageSource = (picture?: string | null, dpr?: number) => {
        const image = picture ?? process.env.GATSBY_ASSETS_URL + '/media/establishment/default.jpg'
        let width = 1440
        if (isLargeScreen) {
            width = 1536
        } else if (isMobileScreen) {
            width = 300
        } else {
            if (typeof window !== 'undefined' && document) {
                width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
            }
        }
        const targetWidth = Math.round((width - spacing * 8 * columns) / columns)
        const targetHeight = Math.round((targetWidth * 3) / 3)
        return {
            height: targetHeight,
            width: targetWidth,
            image: getImage(image, { w: targetWidth, h: targetHeight, fit: 'clip', dpr }),
        }
    }

    const { height } = getImageSource(undefined)
    return (
        <Swiper
            key={images
                .map(({ picture }) =>
                    picture
                        .substring(
                            picture.lastIndexOf('/') + 1,
                            picture.indexOf('?') !== -1 ? picture.indexOf('?') : undefined,
                        )
                        .replace('.', '-'),
                )
                .join('-')}
            height={height}
            onInit={(swiper) => {
                const navigationPrevElement = navigationPrevRef.current
                const navigationNextElement = navigationNextRef.current
                const navigation = swiper.params.navigation
                if (navigationPrevElement && navigationNextElement && navigation) {
                    swiper.navigation.destroy()
                    // @ts-ignore
                    navigation.prevEl = navigationPrevElement
                    // @ts-ignore
                    navigation.nextEl = navigationNextElement
                    swiper.navigation.init()
                    swiper.navigation.update()
                }
            }}
            modules={[Navigation, Zoom, Lazy, Keyboard]}
            centerInsufficientSlides
            slidesPerView={columns}
            lazy
            zoom={isMobileScreen}
            keyboard
            pagination={{
                clickable: true,
                renderBullet: (_, className) => {
                    return (
                        '<span class="' +
                        className +
                        '" style="background-color: ' +
                        (color === 'primary' ? '#fff' : '#000') +
                        '"></span>'
                    )
                },
            }}
            spaceBetween={24}
        >
            <Hidden implementation="css">
                <NavigationButton
                    color={color}
                    component={'div'}
                    ref={navigationPrevRef}
                    className="swiper-button-prev"
                />
                <NavigationButton
                    color={color}
                    component={'div'}
                    ref={navigationNextRef}
                    className="swiper-button-next"
                />
            </Hidden>
            {images.map(({ picture, comment }, index) => {
                const { image, height, width } = getImageSource(picture)
                return (
                    <SwiperSlide
                        zoom={isMobileScreen}
                        key={
                            'images' +
                            index +
                            image
                                .substring(
                                    image.lastIndexOf('/') + 1,
                                    image.indexOf('?') !== -1 ? image.indexOf('?') : undefined,
                                )
                                .replace('.', '-')
                        }
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: height,
                            width: isMobileScreen ? width : 'inherit',
                            overflow: 'hidden',
                        }}
                        itemProp="hasMenu"
                        itemScope
                        itemType="https://schema.org/Menu"
                    >
                        <>
                            <div style={{ position: 'relative' }}>
                                <img
                                    key={'image' + picture}
                                    itemProp="image"
                                    data-src={image}
                                    data-srcset={
                                        getImageSource(image, 1).image +
                                        ' 1x, ' +
                                        getImageSource(image, 2).image +
                                        ' 2x, ' +
                                        getImageSource(image, 3).image +
                                        ' 3x'
                                    }
                                    {...{ content: getImage(picture) }}
                                    className="swiper-lazy"
                                    height="100%"
                                    width="100%"
                                    alt={comment ? comment : t('menu.slate')}
                                />
                                <IconButton
                                    key={'imageZoom' + index}
                                    onClick={() => onZoom(index)}
                                    sx={{
                                        position: 'absolute',
                                        bottom: 8,
                                        right: 8,
                                        bgcolor: 'primary.main',
                                        borderColor: 'secondary.main',
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                    }}
                                    color="primary"
                                    size={isMobileScreen ? 'medium' : 'large'}
                                >
                                    <ZoomOutMapIcon color="secondary" fontSize="inherit" />
                                </IconButton>
                            </div>
                            <div
                                className={clsx('swiper-lazy-preloader', {
                                    'swiper-lazy-preloader-white': color === 'primary',
                                    'swiper-lazy-preloader-black': color === 'secondary',
                                })}
                            />
                        </>
                    </SwiperSlide>
                )
            })}
        </Swiper>
    )
}

export default Slider
