import LanguageIcon from '@mui/icons-material/Language'
import PhoneIcon from '@mui/icons-material/Phone'
import { Container, Grid } from '@mui/material'
import BookingIcon from 'components/icons/BookingIcon'
import DeliveryIcon from 'components/icons/DeliveryIcon'
import { EstablishmentModel } from 'core/models/establishmentModel'
import { usePhoneNumber } from 'core/services/phone/usePhoneNumber'
import { useWebsite } from 'core/services/website/useWebsite'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import Section from './common/section'
import { Title } from './common/title'
import Action from './order/action'

interface Props {
    establishment: EstablishmentModel
    color: 'primary' | 'secondary'
    hubSpotFormInitialized: boolean
}

const LinksSection = React.forwardRef<HTMLDivElement, Props>(
    ({ establishment, color, hubSpotFormInitialized }, ref) => {
        const {
            phoneNumber,
            email,
            website,
            delivery,
            booking,
            bookingModule,
            orderModule,
            businessHours,
            websiteSections,
        } = establishment
        const { displayPhone, validPhone, getPhoneNumber, getInternationalPhone } = usePhoneNumber()
        const { isValidWebsite, getWebsite } = useWebsite()
        const { t } = useTranslation()

        const id = 'links'
        const title = t('menu.links')
        const section = websiteSections?.find((section) => section.section === id)

        const hasBookingModule =
            typeof window !== 'undefined' &&
            hubSpotFormInitialized &&
            bookingModule &&
            validPhone(phoneNumber) &&
            !!email &&
            businessHours &&
            !(
                businessHours?.sunday?.length === 0 &&
                businessHours?.monday?.length === 0 &&
                businessHours?.tuesday?.length === 0 &&
                businessHours?.wednesday?.length === 0 &&
                businessHours?.thursday?.length === 0 &&
                businessHours?.friday?.length === 0 &&
                businessHours?.saturday?.length === 0
            )
        const bookingWebsite = getWebsite(booking, hasBookingModule)
        const hasOrderModule: boolean =
            typeof window !== 'undefined' &&
            hubSpotFormInitialized &&
            orderModule &&
            validPhone(phoneNumber) &&
            !!email &&
            businessHours &&
            !(
                businessHours?.sunday?.length === 0 &&
                businessHours?.monday?.length === 0 &&
                businessHours?.tuesday?.length === 0 &&
                businessHours?.wednesday?.length === 0 &&
                businessHours?.thursday?.length === 0 &&
                businessHours?.friday?.length === 0 &&
                businessHours?.saturday?.length === 0
            )
        const deliveryWebsite = getWebsite(delivery, hasOrderModule)
        return (
            <>
                <Section
                    ref={ref}
                    id={id}
                    title={title}
                    color={color}
                    backgroundImage={section?.backgroundImage ?? undefined}
                    backgroundColor={section?.backgroundColor ?? undefined}
                >
                    <Container maxWidth="sm">
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                zIndex: 3,
                            }}
                            spacing={2}
                        >
                            <Grid item xs={12}>
                                <Title
                                    component="h2"
                                    color={color}
                                    sx={{
                                        color: section?.textColor,
                                    }}
                                >
                                    {title}
                                </Title>
                            </Grid>
                            {validPhone(phoneNumber) && (
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <Action
                                        itemProp="telephone"
                                        content={getInternationalPhone(phoneNumber) as string}
                                        color={color}
                                        href={getPhoneNumber(phoneNumber) ?? ''}
                                        title={displayPhone(phoneNumber)}
                                        Icon={PhoneIcon}
                                        text={
                                            <Trans
                                                i18nKey="establishment.phone"
                                                values={{ phone: displayPhone(phoneNumber) }}
                                            />
                                        }
                                    />
                                </Grid>
                            )}
                            {website && isValidWebsite(website) && (
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <Action
                                        color={color}
                                        href={website}
                                        Icon={LanguageIcon}
                                        text={<Trans i18nKey="establishment.website" />}
                                    />
                                </Grid>
                            )}
                            {deliveryWebsite && (
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <Action
                                        openNewTab={isValidWebsite(deliveryWebsite)}
                                        color={color}
                                        href={deliveryWebsite}
                                        Icon={DeliveryIcon}
                                        text={<Trans i18nKey="establishment.delivery" />}
                                    />
                                </Grid>
                            )}
                            {bookingWebsite && (
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <Action
                                        openNewTab={isValidWebsite(deliveryWebsite)}
                                        color={color}
                                        href={bookingWebsite}
                                        Icon={BookingIcon}
                                        text={<Trans i18nKey="establishment.booking" />}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Container>
                </Section>
            </>
        )
    },
)

export default LinksSection
