import { AppBar, Divider, Link, Toolbar, Typography, useScrollTrigger } from '@mui/material'
import { cumulativeOffset } from 'components/helpers/offset'
import withMuiTheme from 'containers/hoc/withMuiTheme'
import { EstablishmentModel } from 'core/models/establishmentModel'
import { Trans } from 'gatsby-plugin-react-i18next'
import scrollTo from 'gatsby-plugin-smoothscroll'
import React, { memo, useEffect } from 'react'
import MenuItem from './header/menuItem'

interface Props {
    establishment: EstablishmentModel
    sections: string[]
}

const Header: React.FC<Props> = ({ establishment, sections }) => {
    const id = 'title'
    const section = establishment.websiteSections?.find((section) => section.section === id)

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 64,
    })
    const targets: Array<{
        anchorStart: string
        text: string | React.ReactNode
    }> = []
    sections
        .filter((section) => section !== 'footer')
        .forEach((section, index, sections) => {
            const previousSection = index > 1 ? sections[index - 1] : undefined
            const previousSectionBefore = index > 2 ? sections[index - 2] : undefined
            if (sections.includes('links')) {
                if (
                    section === 'order' &&
                    ((previousSection === 'book' && previousSectionBefore === 'links') ||
                        previousSection === 'links')
                ) {
                    return
                }
                if (
                    section === 'book' &&
                    ((previousSection === 'order' && previousSectionBefore === 'links') ||
                        previousSection === 'links')
                ) {
                    return
                }
            }
            targets.push({
                anchorStart: '#' + section,
                text: <Trans i18nKey={'menu.' + section} />,
            })
        })
    const elementStart = document.querySelector(targets[0].anchorStart) as HTMLElement | null
    const thresholdStart = cumulativeOffset(elementStart ?? undefined).top ?? 0
    const triggerFirst = useScrollTrigger({
        disableHysteresis: true,
        threshold: thresholdStart - 8,
    })
    useEffect(() => {
        if (!triggerFirst && window.location.hash) {
            window.history.pushState({}, '', window.location.origin + window.location.pathname)
        }
    }, [triggerFirst])
    const displayMenuItems = () => {
        return targets
            .filter((target) => target.text !== undefined)
            .map((target, index) => (
                <MenuItem
                    key={'MenuItem' + index}
                    color={trigger ? 'primary' : 'secondary'}
                    anchorStart={target.anchorStart}
                    anchorEnd={targets[index + 1]?.anchorStart}
                    text={target.text}
                    sx={{
                        color: trigger ? undefined : section?.textColor,
                    }}
                />
            ))
    }
    return (
        <AppBar color={trigger ? 'secondary' : 'transparent'} elevation={trigger ? 4 : 0}>
            <Toolbar sx={{ justifyContent: 'center' }}>
                <Typography
                    variant="h6"
                    sx={{
                        textTransform: 'uppercase',
                    }}
                >
                    <Link
                        href={''}
                        color={trigger ? 'primary' : 'secondary'}
                        onClick={(e) => {
                            if (typeof window !== 'undefined') {
                                e.preventDefault()
                                window.history.pushState({}, '', '')
                                scrollTo('#___gatsby')
                            }
                        }}
                        underline="hover"
                        sx={(theme) => ({
                            margin: theme.spacing(0, 2),
                            color: trigger ? undefined : section?.textColor,
                        })}
                    >
                        {establishment.name}
                    </Link>
                </Typography>
                <Divider
                    orientation="vertical"
                    flexItem
                    color={trigger ? 'primary' : 'secondary'}
                    sx={{
                        marginTop: 2,
                        marginBottom: 2,
                        bgcolor: section?.textColor ?? (trigger ? 'primary.main' : 'secondary.main'),
                    }}
                />
                {displayMenuItems()}
            </Toolbar>
        </AppBar>
    )
}

export default withMuiTheme(memo(Header))
